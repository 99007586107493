import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Post } from '../../../common/types/post';

import { Wrapper, PostThumbnail } from './styled';
import {PostPreviewBlock} from '../../post-preview-block';

interface PostProps {
  posts: Posts['posts'];
  currentPostIndex: number;
  isPrev: boolean;
}

const PostBlock: React.FunctionComponent<PostProps> = (props) => {
  const {posts, currentPostIndex, isPrev} = props;
  const post = posts.edges[currentPostIndex + (isPrev ? -1 : 1)];
  const matchResultDate = post.node.frontmatter.date.match(/(\d{4})-(\d{2})-(\d{2})/);
  if (!matchResultDate) return null;
  const [_, year, month] = matchResultDate;
  const path = `/blog/${year}/${month}${post.node.frontmatter.path}`;

  return (
    <PostPreviewBlock post={post.node}>
      <p>{isPrev ? 'previous post' : 'next post'}</p>
      <PostThumbnail src={post.node.frontmatter.thumbnail.childImageSharp.fluid.src} alt='Post thumbnail' />
      <p>{post.node.frontmatter.title}</p>
    </PostPreviewBlock>
  );
};

interface Posts {
  posts: { edges: { node: Post }[] };
}

interface Props {
  currentPost: Post
}

const PrevNextPosts: React.FunctionComponent<Props> = (props) => {
  const { posts }: Posts = useStaticQuery(graphql`
      query {
          posts: allMarkdownRemark(
              sort: { fields: [frontmatter___date], order: ASC }
              filter: { fileAbsolutePath: { regex: "/posts/" } }
          ) {
              edges {
                  node {
                      id
                      frontmatter {
                          date
                          path
                          title
                          thumbnail {
                              childImageSharp {
                                  fluid {
                                      ...GatsbyImageSharpFluid
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
  `);
  const currentPost = posts.edges.filter(edge => edge.node.id === props.currentPost.id)[0];
  const currentPostIndex = posts.edges.indexOf(currentPost)
  const currentPostIsFirst = currentPostIndex === 0;
  const currentPostIsLast = currentPostIndex === posts.edges.length - 1;

  return (
    <Wrapper>
      {
        !currentPostIsLast
        &&
        <PostBlock posts={posts} currentPostIndex={currentPostIndex} isPrev={false} />
      }

      {
        !currentPostIsFirst
        &&
        <PostBlock posts={posts} currentPostIndex={currentPostIndex} isPrev={true} />
      }
    </Wrapper>
  );
};

export default PrevNextPosts;