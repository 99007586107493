import React, { useCallback } from 'react';
import { MdClose } from 'react-icons/md';

import { Wrapper, CloseButton } from './styled';

interface Props {
  handleOnClose: () => void;
  children: React.ReactNode;
}

const useHookWithRefCallback = () => {
  const setRef = useCallback(node => {
    if (node) {
      node.focus();
    }
  }, []);

  return [setRef];
};

const Popup = ({ handleOnClose, children }: Props) => {
  const [ref] = useHookWithRefCallback();

  return (
    <Wrapper
      ref={ref}
      className="popup"
      tabIndex={0}
      // onClick={e => {
      //   const target = e.target as Element;
      //   if (target.classList.contains('popup')) {
      //     handleOnClose();
      //   }
      // }}
      onKeyDown={e => {
        if (e.keyCode === 27) {
          handleOnClose();
        }
      }}
    >
      <CloseButton onClick={() => handleOnClose()}>
        <MdClose />
      </CloseButton>
      {children}
    </Wrapper>
  );
};

export default Popup;
