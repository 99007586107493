import styled from 'styled-components';
import { Link } from 'gatsby';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  > * + * {
    margin-left: 2em;
  }
`;

export const PostLink = styled(Link)`
  width: 180px;
  text-align: center;
  margin: 10px;
`;

export const PostThumbnail = styled.img`
  width: 100%;
`;