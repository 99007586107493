import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

export const ImagePreviewSlider = styled(Slider)`
  /* width: 75%; */
  max-width: 1280px;
  padding: 30px;
  margin: 0 auto;

  .slick-slide {
    img {
      display: block;
      width: 100%;
      box-shadow: 0 0 .5em rgba(0, 0, 0, 1);
    }
  }

  .slick-arrow {
    top: 50%;
    &.slick-disabled {
      svg {
        opacity: 0.3;
      }
    }
  }
  .slick-prev {
    left: -1em;
    transform: translate(-100%, -50%);
  }
  .slick-next {
    right: -1em;
    transform: translate(100%, -50%);
  }
`;

export const ImagesRollSlider = styled(Slider)`
  width: 100%;
  margin-top: 1em;

  .slick-track {
    display: flex;
  }
  .slick-slide {
    position: relative;
    /* margin: 0 10px; */
    border: 1px solid transparent;
    cursor: pointer;
    /* transform: scale(0.8, 0.8); */
    transition: border-color .2s;
    /* width: calc(100vw/7); */
    width: 200px;

    img {
      display: block;
      width: 100%;
      /* height: 100%; */
      box-shadow: 0 0 .25em rgba(0, 0, 0, 1);
    }
  }
  .slick-current {
    transform: scale(1, 1);
    border-color: rgba(255, 255, 255, .25);
  }
  .slick-list {
    margin: 0 -10px;
  }
`;

export const ArrowButton = styled.button`
  background-color: rgba(255, 255, 255, 0.05);
  border: none;
  color: rgba(255, 255, 255, .75);
  font-size: 4em;
  cursor: pointer;
  position: absolute;
  display: flex !important;
  padding: 15px;
  border-radius: 100%;
  transition: background-color .2s;
  z-index: 10;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;