import styled from 'styled-components';

export const Wrapper = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  margin-bottom: 30px;

  img,
  video {
    max-width: 500px;
    width: 100%;
  }
`;

export const VideosList = styled.ul`
  > * + * {
    margin-top: 1.5em;
  }
`;

export const VideoItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #777;
  font-style: italic;
  text-align: center;

  > * + * {
    margin-top: .5em;
  }
`;

export const VideoDescription = styled.div`
  line-height: 1.25;
`;