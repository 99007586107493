import styled from 'styled-components';

export const Wrapper = styled.div`
  justify-content: center;
`;

export const ScreenshotsGroup = styled.div`
  margin-top: 50px;
`;

export const ScreenshotsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
`;

export const ScreenshotsGroupTitle = styled.h3`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const ScreenshotsGroupDescription = styled.p`
  margin-bottom: 30px;
`;

export const Screenshot = styled.div`
  cursor: pointer;

  img {
    width: 100%;
  }
`;