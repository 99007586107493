import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { WideLayout } from '../layout';
import PostPreview from './post-preview';
import { QueryData } from '../../common/types/query-data';

const Wrapper = styled.div`
`;
const BackgroundImage = styled.div`
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  filter: blur(7px);
`;

const BlogPost = (props: { data: QueryData }) => {
  const { data } = props;
  const { site, post } = data;

  return (
    <WideLayout>
      <Helmet>
        <title>{site.siteMetadata.title} | {post.frontmatter.title}</title>
      </Helmet>

      <Wrapper>
        <BackgroundImage
          style={{
            backgroundImage: `url('/images/screenshot_3840x2160_2020-07-19_12-44-15.jpg')`
          }}
        />

        <div style={{background: 'linear-gradient(transparent, transparent', padding: '150px 0 10em'}}>
          {/* <PostsList /> */}
          <PostPreview data={data} />
        </div>
      </Wrapper>
    </WideLayout>
  );
};

export const query = graphql`
  query($slug: String!, $absolutePathRegex: String!) {
    site {
      siteMetadata {
        title
      }
    }
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        video
        gifItems {
          src
          description
        }
        gifItemsGroups {
          title
          description
          gifItems {
            src
            description
          }
        }
        screenshotsGroups {
          src
          title
          description
        }
        thumbnail {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)|(gif)/" }
        absolutePath: { regex: $absolutePathRegex }
      }
    ) {
      images: edges {
        image: node {
          absolutePath
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default BlogPost;
