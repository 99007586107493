import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 52em;
  font-family: 'Lato', sans-serif;
  /* box-shadow: 0 0 2em rgba(0, 0, 0, .75); */
`;
export const MainContent = styled.div`
  /* box-shadow: 0 0 1em rgba(0, 0, 0, .5); */
  box-shadow: 0 0 1em rgba(0, 0, 0, .75);
`;

export const Header = styled.div`
  /* height: 400px; */
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; */

  img {
    width: 100%;
    display: block;
  }
`;

export const Body = styled.div`
  background-color: #fff;
  padding: 3em;
`;

export const Title = styled.h2`
  margin-top: 0;
  font-size: 32px;
`;

export const Date = styled.div`
  font-size: 16px;
  margin-top: 1em;
  margin-bottom: 30px;
  color: #333;
`;

export const Excerpt = styled.div`
  font-size: 20px;
  line-height: 1.4;

  p, ul {
    &:not(:first-child) {
      margin-top: 1em;
    }
  }

  ul {
    list-style: disc;
    padding-left: 1em;
  }
`;

export const EmbedVideoBlock = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 30px;
`;

export const Footer = styled.div`
  margin-top: 5em;

  > * + * {
    margin-top: 2em;
  }

  h2 {
    color: rgba(255, 255, 255, .75);
    font-size: 2.5em;
    text-align: center;
    text-shadow: 0 0 3px #000;
  }
`;


// Items groups

export const ItemsGroupsWrapper = styled.div`
  margin-top: 50px;
`;

export const ItemsGroupTitle = styled.h3`
  font-size: 28px;
  /* font-weight: bold; */
  padding-bottom: .5em;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
`;

export const ItemsGroupDescription = styled.p`
  margin-bottom: 30px;
  line-height: 1.25;
`;

export const ItemsGroupGrid = styled.div<{col: number}>`
  display: grid;
  grid-template-columns: repeat(${props => props.col}, 1fr);
  grid-gap: 10px;
`;