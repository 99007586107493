import React, { useState } from 'react';

import { QueryData } from '../../../../common/types/query-data';
import Popup from '../../../popup';
import ImagesSlider from '../../../images-slider';

import {
  ItemsGroupsWrapper,
  ItemsGroupTitle,
  ItemsGroupDescription,
  ItemsGroupGrid,
} from '../styled';
import { Wrapper, Screenshot } from './styled';

interface SliderState {
  isActive: boolean;
  images: { src: string; absolutePath: string }[] | null;
  activeImage: string;
}

const Screenshots = (props: { data: QueryData }) => {
  const [imagesSliderState, setImagesSliderState] = useState<SliderState>({
    isActive: false,
    images: null,
    activeImage: '',
  });

  const { data } = props;
  const { post, allFile } = data;
  const { frontmatter } = post;
  const allScreenshots = allFile.images.map(image => {
    const { childImageSharp, absolutePath } = image.image;
    return { src: childImageSharp.fluid.src, absolutePath: absolutePath };
  });
  const screenshotsGroups =
    frontmatter.screenshotsGroups &&
    frontmatter.screenshotsGroups.map(screenshotsGroup => ({
      screenshotsGroup,
      screenshots: allScreenshots.filter(screenshot =>
        screenshot.absolutePath.includes(screenshotsGroup.src)
      ),
    }));

  const RenderScreenshot = (
    screenshot: { src: string; absolutePath: string },
    screenshotGroupSrc?: string
  ) => (
    <Screenshot
      key={screenshot.src}
      onClick={() => {
        const images = allScreenshots.filter(screenshot => {
          return screenshot.absolutePath.includes(screenshotGroupSrc || '')
        });

        setImagesSliderState({
          isActive: true,
          images,
          activeImage: screenshot.src,
        });
      }}
    >
      <img src={screenshot.src} className="lazyload" alt="Screenshot" />
    </Screenshot>
  );

  return (
    <Wrapper>
      {
        screenshotsGroups
        ?
        (
          screenshotsGroups.map(sg => {
            return (
              <ItemsGroupsWrapper key={sg.screenshotsGroup.src}>
                <ItemsGroupTitle>{sg.screenshotsGroup.title}</ItemsGroupTitle>

                <ItemsGroupDescription>
                  {sg.screenshotsGroup.description}
                </ItemsGroupDescription>

                <ItemsGroupGrid col={3}>
                  {
                    sg.screenshots.map(screenshot => {
                      return RenderScreenshot(screenshot, sg.screenshotsGroup.src);
                    })
                  }
                </ItemsGroupGrid>
              </ItemsGroupsWrapper>
            );
          })
        )
        :
        (
          <ItemsGroupGrid col={3}>
            {allScreenshots.map(screenshot => RenderScreenshot(screenshot))}
          </ItemsGroupGrid>
        )
      }

      {imagesSliderState.isActive && (
        <Popup
          handleOnClose={() => {
            setImagesSliderState({
              isActive: false,
              images: [{ src: '', absolutePath: '' }],
              activeImage: '',
            });
          }}
        >
          <div style={{maxWidth: '1280px'}}>
            <ImagesSlider
              images={
                imagesSliderState.images
                  ? imagesSliderState.images.map(image => image.src)
                  : []
              }
              activeImage={imagesSliderState.activeImage}
            />
          </div>
        </Popup>
      )}
    </Wrapper>
  );
};

export default Screenshots;
