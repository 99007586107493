import React from 'react';

import { QueryData } from '../../../common/types/query-data';

import Videos from './videos';
import Screenshots from './screenshots';
import {
  Wrapper,
  MainContent,
  Header,
  Body,
  Title,
  Date,
  Excerpt,
  EmbedVideoBlock,
  Footer,
} from './styled';
import PrevNextPosts from '../prev-next-posts';

const PostPreview = (props: {data: QueryData}) => {
  const { data } = props;
  const { post } = data;
  const { frontmatter, html } = post;

  return (
    <Wrapper>
      {/* <Header style={{backgroundImage: `url(${frontmatter.thumbnail.childImageSharp.fluid.src})`}}> */}
      <MainContent>
        <Header>
          <img src={frontmatter.thumbnail.childImageSharp.fluid.src} alt=""/>
        </Header>

        <Body>
          <Title>{frontmatter.title}</Title>
          <Date>{frontmatter.date}</Date>
          <Excerpt dangerouslySetInnerHTML={{ __html: html }} />

          {
            frontmatter.video
            &&
            <EmbedVideoBlock>
              <iframe
                title="Post video"
                width="560"
                height="315"
                src={frontmatter.video}
                frameBorder="0"
                // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </EmbedVideoBlock>
          }

          <Videos post={post} />
          <Screenshots data={data} />
        </Body>
      </MainContent>

      <Footer>
        <div>
          <h2>Read more:</h2>
        </div>

        <div>
          <PrevNextPosts currentPost={post} />
        </div>
      </Footer>
    </Wrapper>
  );
};

export default PostPreview;
