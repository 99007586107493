import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 20;
`;

const ButtonStyles = `
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  display: flex !important;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  transition-duration: 200ms;
  z-index: 10;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

export const CloseButton = styled.button`
  ${ButtonStyles};
  top: 20px;
  right: 20px;
`;