import React, { useState } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import Slider from 'react-slick';

import {
  ImagePreviewSlider,
  ImagesRollSlider,
  ArrowButton,
} from './styled';

interface Props {
  images: string[];
  activeImage?: string;
}

const ImagesSlider = ({ images, activeImage }: Props) => {
  const [slider1, setSlider1] = useState<Slider>();
  const [slider2, setSlider2] = useState<Slider>();

  return (
    <div>
      <ImagePreviewSlider
        asNavFor={slider2}
        ref={slider => setSlider1(slider || undefined)}
        infinite={true}
        fade={true}
        initialSlide={activeImage ? images.indexOf(activeImage) : 0}
        prevArrow={
          <ArrowButton>
            <MdChevronLeft />
          </ArrowButton>
        }
        nextArrow={
          <ArrowButton>
            <MdChevronRight />
          </ArrowButton>
        }
      >
        {images.map(image => (
          <div key={image}>
            <div style={{padding: '.5em'}}>
              <img src={image} className='lazyload' />
            </div>
          </div>
        ))}
      </ImagePreviewSlider>

      <ImagesRollSlider
        asNavFor={slider1}
        ref={slider => setSlider2(slider || undefined)}
        initialSlide={activeImage ? images.indexOf(activeImage) : 0}
        arrows={false}
        focusOnSelect={true}
        centerMode={true}
        infinite={true}
        variableWidth={true}
      >
        {images.map(image => (
          <div key={image}>
            <div style={{padding: '.5em'}}>
              <img src={image} className='lazyload' />
            </div>
          </div>
        ))}
      </ImagesRollSlider>
    </div>
  );
};

export default ImagesSlider;
