import React from 'react';

import { Post } from '../../../../common/types/post';

import {
  ItemsGroupsWrapper,
  ItemsGroupTitle,
  ItemsGroupDescription,
  ItemsGroupGrid,
} from '../styled';
import { Wrapper, VideosList, VideoItem, VideoDescription } from './styled';

const VideoBlock = (props: { link: string }) => {
  const { link } = props;
  const isGfycat = link.includes('gfycat.com');

  if (isGfycat) {
    const isGfycatIframe = link.includes('gfycat.com/ifr');

    if (isGfycatIframe) {
      return (
        <iframe
          src={link}
          frameBorder="0"
          scrolling="no"
          allowFullScreen
          width="640"
          height="404"
        ></iframe>
      );
    } else {
      const arr = link.split('/');
      const gfycatName = arr[1];
      if (!gfycatName) return null;

      return (
        <video
          controls={true}
          muted={true}
          poster={`https://thumbs.gfycat.com/${gfycatName}-mobile.jpg`}
        >
          <source
            src={`https://thumbs.gfycat.com/${gfycatName}-mobile.mp4`}
            type="video/mp4"
          />
          <source
            src={`https://giant.gfycat.com/${gfycatName}.webm`}
            type="video/webm"
          />
          <source
            src={`https://giant.gfycat.com/${gfycatName}.mp4`}
            type="video/mp4"
          />
          {/* duplicate? */}
          <source
            src={`https://thumbs.gfycat.com/${gfycatName}-mobile.mp4`}
            type="video/mp4"
          />
        </video>
      );
    }
  } else {
    const ext = link.split('.').pop();

    if (ext === 'gif') {
      return <img src={link} alt="" />;
    } else if (ext === 'mp4') {
      return <video src={link} controls={true} muted={true} />;
    } else {
      return null;
    }
  }
};

const Videos = (props: { post: Post }) => {
  const { post } = props;
  const { frontmatter } = post;

  return (
    <Wrapper>
      {
        frontmatter.gifItemsGroups && frontmatter.gifItemsGroups.length > 0
        &&
        frontmatter.gifItemsGroups.map(group => (
          <ItemsGroupsWrapper key={group.title}>
            <ItemsGroupTitle>{group.title}</ItemsGroupTitle>

            <ItemsGroupDescription>{group.description}</ItemsGroupDescription>

            {/* <ItemsGroupGrid col={2}> */}
              <VideosList>
                {
                  group.gifItems.map(gif => {
                    const link = gif.src;

                    return (
                      <li key={link}>
                        <VideoItem>
                          <div>
                            <VideoBlock link={link} />
                          </div>

                          {gif.description && (
                            <VideoDescription>{gif.description}</VideoDescription>
                          )}
                        </VideoItem>
                      </li>
                    );
                  })
                }
              </VideosList>
            {/* </ItemsGroupGrid> */}
          </ItemsGroupsWrapper>
        ))
      }

      {
        frontmatter.gifItems && frontmatter.gifItems.length > 0
        &&
        <VideosList>
          {
            frontmatter.gifItems.map(gifItem => {
              const link = gifItem.src;
              const description = gifItem.description && gifItem.description;

              return (
                <li key={link}>
                  <VideoItem>
                    <div>
                      <VideoBlock link={link} />
                    </div>

                    {description && <p>{description}</p>}
                  </VideoItem>
                </li>
              );
            })
          }
        </VideosList>
      }
    </Wrapper>
  );
};

export default Videos;
